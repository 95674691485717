import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
	Inject,
	Injectable,
	PLATFORM_ID,
	Renderer2,
	RendererFactory2,
} from '@angular/core';

declare global {
	interface Window {
		dataLayer: unknown[];
		hasLoadedGtmTags?: boolean;
	}
}

@Injectable({
	providedIn: 'root',
})
export class GoogleTagManagerService {
	private readonly renderer: Renderer2;
	private gtmId: string | undefined;

	constructor(
		@Inject(DOCUMENT) public document: Document,
		@Inject(PLATFORM_ID) public platformId: string,
		readonly rendererFactory: RendererFactory2,
	) {
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	init(gtmId: string | undefined) {
		if (!gtmId) {
			return;
		}
		this.gtmId = gtmId;
		if (isPlatformBrowser(this.platformId) && window.hasLoadedGtmTags) {
			return;
		}

		// Script to prevent loading scripts multiple times
		if (isPlatformServer(this.platformId)) {
			const verificationScriptTag = this.renderer.createElement('script');
			verificationScriptTag.innerHTML = 'window.hasLoadedGtmTags = true;';
			this.renderer.appendChild(
				this.document.head,
				verificationScriptTag,
			);
		}

		this.createGoogleTagManagerScript();
		this.createGoogleTagManagerNoScript();
	}

	/**
	 * Appends google tag manager script to the head of document
	 */
	private createGoogleTagManagerScript() {
		if (!this.gtmId) {
			return;
		}
		const head: HTMLElement = this.document.head;
		const scriptElement = this.renderer.createElement('script');

		scriptElement.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','${this.gtmId}');`;

		this.renderer.insertBefore(head, scriptElement, head.firstChild);
	}

	/**
	 * Creates no script iframe for older browsers
	 */
	private createGoogleTagManagerNoScript() {
		if (!this.gtmId) {
			return;
		}
		const body: HTMLElement = this.document.body;
		const noScriptElement = this.renderer.createElement('noscript');
		noScriptElement.innerText = `<iframe src="https://www.googletagmanager.com/ns.html?id=${this.gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
		this.renderer.insertBefore(body, noScriptElement, body.firstChild);
	}

	/**
	 * Push to gtm dataLayer
	 */
	push<T>(event: T): void {
		if (!this.gtmId || !isPlatformBrowser(this.platformId)) {
			return;
		}

		window.dataLayer.push(event);
	}
}
